<template>
   <layout-page  :classes="pageClasses">
       <section v-if="dataErrored">
      <p>We're sorry, we're not able to retrieve this information at the moment, please try back later</p>
    </section>
    <section v-else>
       <loading v-if="dataLoading"></loading>
       <template v-else>
     <HeadPage :rawTitle="page[0].title.rendered" :content="page[0].content.rendered" :image="page[0].fimg_url.full" :credits="credits" />
     
    <div class="container-fluid d-none d-lg-block h-100vh bg__img acknowledge" v-bind:style="{ backgroundImage: 'url(' + page[0].acf.image_1 + ')' }">
        <div class="row justify-content-center">
          <div class="col-12 col-lg-10">
            <div class="row position-relative">
              <div class="col-12 col-lg-6 col-xl-5 position-relative">
                <div class="w-100 copy">
                 <div class="w-100 p-lg-4 p-xl-5 bg__secondary position-relative z-index-2">
                    <h2 class="text-uppercase tx__w-bold mx-auto pb-4" v-html="renderTitle(page[0].acf.title_1)">
                    </h2>
                    <div class="w-100 py-3" v-html="page[0].acf.copy_1"></div>
                  </div>
                  <span class="stroke stroke-bottom-right"></span>

                </div>
              </div>
            </div>
          </div>
        </div>
    </div>

    <div class="container-fluid bg__secondary d-lg-none pt-5">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-12">
              <figure class="figure figure__rect">
                <img :src="page[0].acf.image_1" :alt="page[0].acf.title_1" />
                <span class="stroke stroke-top-right"></span>
              </figure>
            </div>
            <div class="col-12">
              <h2 class="text-uppercase tx__w-bold mx-auto pb-4 text-center tx__break" v-html="renderTitle(page[0].acf.title_1)">
                    </h2>
                    <div class="w-100 py-3" v-html="page[0].acf.copy_1"></div>
            </div>

          </div>
        </div>
      </div>
    </div>

     <div class="container-fluid bg__secondary py-3 py-lg-5">
      <div class="row py-5">
        <div class="col-12">
          <div class="row align-items-center">
            <div class="col-12 col-lg-7 col-xl-8">
              <figure class="figure figure__rect">
                <img :src="page[0].acf.image_2" :alt="page[0].acf.title_2" />
                <span class="stroke stroke-top-right"></span>
              </figure>
            </div>
            <div class="col-12 col-lg-5  col-xl-4 bg__secondary mn-l-8  py-3 py-xl-5 z-index-2">
              <div class="w-100 px-3 px-xl-5 py-3 py-xl-4">
                <h2 class="text-uppercase tx__w-bold mx-auto pb-4 text-center text-lg-left" v-html="renderTitle(page[0].acf.title_2)"></h2>
                <div class="w-100 py-3" v-html="page[0].acf.copy_2">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
     <div class="container-fluid bg__white giving-back pt-5">
          <div class="row py-3 py-lg-5">
            <div class="col-12">
              <div class="row justify-content-end align-items-center">
                <div class="col-12 col-lg-4 bg__white py-3 py-xl-5 z-index-2 mn-r-8 order-1 order-lg-0">
                  <div class="w-100 px-3 px-xl-4 py-4">
                    <h2 class="text-uppercase tx__w-bold mx-auto pb-4 text-center text-lg-left " v-html="renderTitle(page[0].acf.title_3)">
                    </h2>
                    <div class="w-100 py-3" v-html="page[0].acf.copy_3"></div>
                      <splide ref="charities"  :options="options" v-if="page[0].acf.organisations.length">
                        <splide-slide v-for="(i, index) in page[0].acf.organisations" :key="index">
                          <div class="w-100 h-100 d-flex align-items-center px-5">
                              <a :href="i.link.url" class="d-block px-xl-4" target="_blanck"><img :src="i.logo" alt="Organisation" class="img--grayscale px-3"></a>
                          </div>
                        </splide-slide>
                      </splide>
                  </div>
                </div>
                <div class="col-12 col-lg-8 order-0 order-lg-1">
                  <figure class="figure figure__rect">
                    <img :src="page[0].acf.image_3" alt="" class="img--grayscale"/>
                    <span class="stroke stroke-top-left"></span>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
    </template>
    </section>
    </layout-page>
</template>

<script>
import LayoutPage from "@/layouts/LayoutPage.vue";
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import HeadPage from "@/components/template-parts/HeadPage.vue";
import Loading from '@/components/Loading.vue';
export default {
components: {
    LayoutPage,HeadPage, Splide, 
    SplideSlide, Loading
  },
   data() {
    return {
      fetchSlug: String,
      itLoads: false,
      dataLoading: true,
      dataErrored: false,
      page: [
        {
          title:{
            rendered: 'this.$route.name'
          }
        }
      ],
      credits: 'Photo credit: Lisa Maree Williams/Getty Images',
      refe: Array,
      pageTitle: Array,
      pageClasses: ["internal-page", this.$route.name],
      options: {
        perPage: 1,
        autoplay: true,
        arrows: true,
        pagination: false,
        type: "loop",
        gap: "3rem",
        easing: "ease"
        
      },
    };
  },
  head() {
    return {
      title: this.page[0]._yoast_wpseo_title,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.page[0]._yoast_wpseo_metadesc,
        },
      ],
    };
  },
   methods: {
      fetchingData() {
        this.fetchSlug = this.$route.path;
        this.$axios
          .get( this.$axios.defaults.baseURL +'wp/v2/pages/?slug=' +this.fetchSlug )
          .then((response) => {
            this.page = response.data;
            this.itLoads = true
          })
          .catch((error) => {
            console.log(error)
            this.dataErrored = true
          }).finally(() => this.dataLoading = false);
      },
      splitString(stg){
          let str = stg;
          let splStrt = str.split(" ");
          return splStrt;
      },
      renderTitle(stg){
        let inputstg = this.splitString(stg);
        inputstg[0] = '<span class="tx__c-primary">'+inputstg[0]+'</span>';
        inputstg = inputstg.join(' ');
        return inputstg;
      }
  },
  created() {
    this.fetchingData();
  }
}
</script>

<style>

</style>