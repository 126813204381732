<template>
  <div class="page-header">
      <div class="container-fluid bg__secondary ">
          <div class="row justify-content-center h-100">
            <div class="col-12 col-lg-10 title-section">
            <h1 class="text-uppercase tx__w-bold" v-html="renderTitle(rawTitle)"></h1>
            </div>
          </div>
        </div>
        <div class="container-fluid py-2 py-lg-5 page-content">
          <div class="row position-relative">
            <div class="col-12 col-lg-4 offset-lg-1 order-1 order-lg-0">
                  <div class="w-100"  v-html="content"></div>
            </div>
            <div class="col-12 col-lg-4 featured-image order-0 order-lg-1 px-4 px-lg-0">
              <figure class="figure figure__square figure--cover ">
                <img :src="image" :alt="rawTitle" class="img--grayscale">
                <span class="stroke stroke-top-left"></span>
                <span class="credits" v-html="credits"></span>
            </figure>
            </div>
          </div>
        </div>
  </div>
</template>

<script>
export default {
    props:{
        rawTitle: String,
        content: String,
        image: String,
        credits: String
    },
    methods:{
       splitString(stg){
          let str = stg;
          let splStrt = str.split(" ");
          return splStrt;
      },
      renderTitle(stg){
        let inputstg = this.splitString(stg);
        inputstg[0] = '<span class="tx__c-primary">'+inputstg[0]+'</span>';
        inputstg = inputstg.join(' ');
        return inputstg;
      }
    }
}
</script>

<style>

</style>